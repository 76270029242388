@tailwind base;
@tailwind components;
@tailwind utilities;

.logoTurboPizzaDefault {
    width: 177px;
    height: 35px;
}

.logoTurboPizzaBranch {
    width: 135px;
    height: 27px;
}

.shadow-container {
    -webkit-appearance: none;

    /*box-shadow: '0px 2px 5px rgba(0, 0, 0, 0.25), 0px 0px 8px rgba(0, 0, 0, 0.08)'*/
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
}

.shadow-buttons {
    -webkit-appearance: none;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
    /*0px 2px 5px rgba(0, 0, 0, 0.25), 0px 2px 4px rgba(0, 0, 0, 0.08), 0px 3px 6px rgba(0, 0, 0, 0.1), 0px 0px 10px rgba(0, 0, 0, 0.12)*/

}


.productListWrapper,
.categoryListWrapper {
    @apply flex flex-wrap gap-2 relative mx-auto px-2 justify-center items-stretch xs:px-5 lg:gap-5 lg:container xl:gap-16 2xl:gap-24;
}

.categoryListWrapper {
    @apply mb-16 2xl:mb-24;
}

.productListWrapper > div,
.categoryListWrapper > div {
    @apply relative;

    min-width: calc(50% - .501rem / 2);
    max-width: calc(50% - .501rem / 2);
}

.btn-global {
    color: var(--bg-turbo-1);
}

.tracking-0-01em {
    letter-spacing: 0.01em;
}

.tracking-0-03em {
    letter-spacing: 0.03em;
}

.tracking-0-04em {
    letter-spacing: 0.04em;
}

.tracking-0-05em {
    letter-spacing: 0.05em;
}

.leading-15px {
    line-height: 15px;
}

.leading-17px {
    line-height: 17px;
}

.bottom-line {
    position: relative;
    width: 20%;
}

.bottom-line::after {
    position: absolute;
    content: "";
    width: 100%;
    left: 0;
    bottom: 3px;
    border-bottom: 1px solid black;
}

.fk-screamer-title {
    font-family: FK_SCREAMER;
    font-size: 36px;
    letter-spacing: 0.03em;
}

/* TODO: ŠF: z 20px jsem změnil na 40px potřeba ohlídat zbytek graficky na stránkách */
.fk-screamer-h3 {
    font-family: FK_SCREAMER, serif;
    font-size: 20px;
    line-height: 1.2;
    letter-spacing: 0.05em;
}

.item-visibility {
    display: none;
}

.scroller-hidden {
    right: -200px !important;
    transition: 1s;
}

.scroller-visible {
    right: 0px !important;
    transition: 1s;
}

.horizontal-slider-normal {
    top: 140px !important;
    transition: 0.5s;
}

.horizontal-slider-normal-with-tags {
    top: 350px !important;
    transition: 0.5s;
}

.horizontal-slider-moved {
    top: 84px !important;
    transition: 0.5s;
}

.horizontal-slider-moved-with-tags {
    top: 84px !important;
    transition: 0.5s;
}

.reportmodal-visible {
    opacity: 100 !important;
    height: auto !important;
    transition: 1s;
}

.scale-menu {
    animation: scale 0.5s normal forwards ease-in-out;
}

@keyframes scale {
    0% {
        transform: scale(1, 1);
    }
    50% {
        transform: scale(1.5, 1.5);
    }
    100% {
        transform: scale(1, 1);
    }
}

.mainMenu {
    @apply hidden lg:flex px-0 ml-auto md:space-x-12 font-semibold;
}

.region-container {
    display: grid;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    column-gap: 14px;
    row-gap: 60px;
    padding: 60px 16px;
}


.banner-hp-top {
    min-height: 390px;
}

.banner-hp-undersearch {
    min-height: 250px;
}

.banner-hp-bottom {
    min-height: 150px;
}

.banner-branch-top {
    min-height: 390px;
}

.banner-branch-category {
    min-height: 220px;
}

.banner-branch-bottom {
    min-height: 170px;
}


/*   Mobile banners   */
.banner-hp-top-mobile {
    min-height: 80vw ;

}

.banner-hp-undersearch-mobile {
    min-height: 30vw;
}

.banner-hp-bottom-mobile {
    min-height: 150px;
    margin-top: 32px;
    margin-bottom: 32px;
}

.banner-branch-top-mobile {
    min-height: 50vw ;
}

.banner-branch-bottom-mobile {
    min-height: 150px;
}
.scroller-element {
    visibility: hidden;
    transition: bottom 0.5s ease-in-out;

}
.track{
    background: #1d2e38;
}
.track-0{
    background: #1d2e38;
}
.track-1{
    background: #454c54;
}


.row-entry {
    grid-template-columns: repeat(3, minmax(0, 1fr));
}

/*
 *  FOR SCREEN MIN WIDTH 400px (XS)
 */
@media (min-width: theme('screens.xs')) {
    .region-container {
        padding: 60px 24px;
    }

    .banner-branch-category {
        min-height: 270px;
    }
    /*   Mobile banners   */
    .banner-hp-top-mobile {
        min-height: 50vw ;
        /*min-height: 250px;*/

    }
    .banner-branch-top-mobile {
        min-height: 50vw ;
    }
}


/*
 *  FOR SCREEN MIN WIDTH 640px (SM)
 */
@media (min-width: theme('screens.sm')) {
    .fk-screamer-h3 {
        font-family: FK_SCREAMER, serif;
        font-size: 45px;
        line-height: 1.3;
        letter-spacing: 0.05em;
    }

    .region-container {
        padding: 60px 32px;
    }

    .banner-branch-category {
        min-height: 300px;
    }

    /*   Mobile banners   */
    .banner-hp-top-mobile {
        /*min-height: 450px;*/
    }

    .banner-branch-top-mobile {
        /*min-height: 450px;*/
    }


    .banner-branch-category-mobile {
        min-height: 480px;
    }


    .productListWrapper > div,
    .categoryListWrapper > div {
        min-width: calc(33.33% - 1.001rem / 3);
        max-width: calc(33.33% - 1.001rem / 3);
    }
}

/*
*  FOR SCREEN MIN WIDTH ???? (MD)
*/
@media (min-width: theme('screens.md')) {
    .banner-branch-category {
        min-height: 360px;
    }
}

/*
 *  FOR SCREEN MIN WIDTH 1024px (LG)
 */
@media (min-width: theme('screens.lg')) {
    .row-entry {
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }

    .horizontal-slider-normal {
        top: 136px !important;
        transition: 0.5s;
    }

    .horizontal-slider-moved {
        top: 80px !important;
        transition: 0.5s;
    }

    .region-container {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        column-gap: 70px;
        row-gap: 60px;
        padding: 60px 1.5rem;
    }

    .logoTurboPizzaBranch {
        width: 177px;
        height: 35px;
    }

    .item-visibility {
        display: block;
    }

    .productListWrapper > div,
    .categoryListWrapper > div {
        min-width: calc(33.33% - 2.501rem / 3);
        max-width: calc(33.33% - 2.501rem / 3);
    }

    .productDetail {
        border-width: 0 !important;
        background: transparent !important;
    }

    .productDetail .productDetailContent {
        color: #000 !important;
        background: transparent !important;
    }

    .banner-hp-top {
        min-height: 490px;
    }

    .banner-hp-bottom {
        min-height: 200px;
    }

    .banner-branch-top {
        min-height: 490px;
    }

    .banner-branch-category {
        min-height: 480px;
    }

    .banner-branch-bottom {
        min-height: 690px;
    }

}


/*
 *  FOR SCREEN MAX WIDTH 1024px (LG)
 */
@media (max-width: 1023px) {

    .mainMenu {
        @apply h-screen fixed w-full space-y-5 uppercase text-lg fixed top-0 left-0 flex-col z-40 opacity-100 justify-center flex w-full;
        background: theme('colors.red.500');
        background: linear-gradient(145deg, theme('colors.red.500') 35%, theme('colors.red.500') 100%);
        transition: opacity 800ms ease;
    }

    .mainMenu.closed {
        z-index: -1;
        opacity: 0;
        left: -100%;
        position: fixed;
    }
}

/*
 *  FOR SCREEN MIN WIDTH 1280px (XL)
 */
@media (min-width: theme('screens.xl')) {
    .fk-screamer-title {
        font-size: 80px;
    }

    .fk-screamer-h3 {
        font-family: FK_SCREAMER, serif;
        font-size: 60px;
        line-height: 1.3;
        letter-spacing: 0.05em;
    }

    .region-container {
        grid-template-columns: repeat(2, minmax(0, 1fr));
        column-gap: 140px;
        row-gap: 60px;
        padding: 0 1.5rem 60px 1.5rem;
    }

    .region {
        column-gap: 15px;
        row-gap: 18px;
        margin-top: 74px;
    }

    .productListWrapper > div,
    .categoryListWrapper > div {
        min-width: calc(33.33% - 8.001rem / 3);
        max-width: calc(33.33% - 8.001rem / 3);
    }

    .banner-hp-top {
        min-height: 590px;
    }

    .banner-hp-undersearch {
        min-height: 250px;
    }

    .banner-branch-top {
        min-height: 590px;
    }
}

/*
 *  FOR SCREEN MIN WIDTH 1536px (2XL)
 */
@media (min-width: theme('screens.2xl')) {
    .productListWrapper > div,
    .categoryListWrapper > div {
        min-width: calc(33.33% - 12.001rem / 3);
        max-width: calc(33.33% - 12.001rem / 3);
    }

    .banner-hp-top {
        min-height: 690px;
    }

    .banner-branch-top {
        min-height: 690px;
    }

}

@font-face {
    font-family: "FK_SCREAMER";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url("../assets/fonts/FKScreamer/FKScreamer-Upright.ttf");
}

@font-face {
    font-family: "LIBRE_FRANKLIN";
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url("../assets/fonts/LibreFranklin/LibreFranklin-Regular.ttf");
}

@font-face {
    font-family: "LIBRE_FRANKLIN_BOLD";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url("../assets/fonts/LibreFranklin/LibreFranklin-Bold.ttf");
}

@layer utilities {
    @variants responsive {
        /* Chrome, Safari and Opera */
        .no-scrollbar::-webkit-scrollbar {
            display: none;
        }

        .no-scrollbar {
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */
        }
    }
}

@layer components {
    /*  Solid backgorund buttons*/
    .button-background-solid {
        @apply bg-turbo-background;
        @apply text-black
    }

    .button-background-solid:hover {
        @apply bg-turbo-backgroundHover;
        @apply text-white;
    }

    .button-background-solid:active {
        @apply bg-turbo-backgroundClick;
        @apply text-white

    }

    .button-background-primary-solid {
        @apply bg-turbo-primary;
        @apply text-white

    }

    .button-background-primary-solid:hover {
        @apply bg-turbo-primaryHover;
        @apply text-black

    }

    .button-background-primary-solid:active {
        @apply bg-turbo-primaryClick;
        @apply text-black

    }

    .button-background-secondary-solid {
        @apply bg-turbo-secondary;
        @apply text-white

    }

    .button-background-secondary-solid:hover {
        @apply bg-turbo-secondaryHover;
        @apply text-black

    }

    .button-background-secondary-solid:active {
        @apply bg-turbo-secondaryClick;
        @apply text-black

    }

    /* Ghost background buttons*/
    .button-background-ghost {
        @apply bg-transparent
    }

    .button-background-ghost:hover {
        @apply bg-turbo-grayHover
    }

    .button-background-ghost:active {
        @apply bg-turbo-grayClick
    }

    .button-background-primary-ghost {
        @apply bg-transparent
    }

    .button-background-primary-ghost:hover {
        @apply bg-turbo-grayHover
    }

    .button-background-primary-ghost:active {
        @apply bg-turbo-grayClick
    }

    .button-background-secondary-ghost {
        @apply bg-transparent
    }

    .button-background-secondary-ghost:hover {
        @apply bg-turbo-grayHover
    }

    .button-background-secondary-ghost:active {
        @apply bg-turbo-grayClick
    }

    /*  Outline background buttons*/
    .button-background-outline {
        @apply border-2;
        @apply border-turbo-background
    }

    .button-background-outline:hover {
        @apply border-2;
        @apply border-turbo-backgroundHover
    }

    .button-background-outline:active {
        @apply border-2;
        @apply border-turbo-backgroundClick
    }

    .button-background-primary-outline {
        @apply border-2;
        @apply border-turbo-primary
    }

    .button-background-primary-outline:hover {
        @apply border-2;
        @apply border-turbo-primaryHover
    }

    .button-background-primary-outline:active {
        @apply border-2;
        @apply border-turbo-primaryClick
    }

    .button-background-secondary-outline {
        @apply border-2;
        @apply border-turbo-secondary
    }

    .button-background-secondary-outline:hover {
        @apply border-2;
        @apply border-turbo-secondaryHover
    }

    .button-background-secondary-outline:active {
        @apply border-2;
        @apply border-turbo-secondaryClick
    }

    .button-disabled {
        @apply bg-turbo-gray
    }
}

